import { useState, useCallback } from 'react'
import { Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { PhotoshopPicker } from 'react-color'

const ColorPage = () => {
  const [color, setColor] = useState('')

  const handleColorChange = useCallback((color) => {
    setColor(color)
  }, [])

  return (
    <div className="page">
      <Helmet>
        <title>Color Picker</title>
        <meta charSet="utf-8" />
        <meta name="description" content="This is the screen to generate the color code. You can get RGB, HSV, and HEX values." />
        <meta name="keywords" content="color picker, color, painter"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Color Picker" />
        <meta property="og:description" content="This is the screen to generate the color code." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        Color Picker
      </Typography>
      <div className="small-vertical-margin-div" />
      <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
        This is the screen to generate the color code.
      </Typography>
      <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
        You can get RGB, HSV, and HEX values.
      </Typography>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PhotoshopPicker header={null} color={color} onChange={(color) => handleColorChange(color.hex)} />
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our Color Picker Page</h1>
        <p>
          Color Picker is a versatile tool for adding color picker functionalities to your applications. Whether you're designing a web application, creating digital artwork, or customizing user
          interfaces, our Color Picker offers a seamless solution for selecting and applying colors with ease.
        </p>
        <br />
        <h2>What can you do with our Color Picker?</h2>
        <ul>
          <li>Color Selection: Easily select colors using various input methods, including sliders, swatches, and color pickers.</li>
          <br />
          <li>Customization Options: Customize the appearance and behavior of the color picker to match your application's design and requirements.</li>
          <br />
          <li>Accessibility: Ensure accessibility by providing options for color contrast and compatibility with screen readers.</li>
          <br />
          <li>Integration with React: Seamlessly integrate the Color library into your applications with minimal setup and configuration.</li>
          <br />
          <li>Real-time Preview: Preview selected colors in real-time to see how they will appear in your application or design.</li>
          <br />
          <li>Responsive Design: Support for responsive design, ensuring optimal user experience across different devices and screen sizes.</li>
          <br />
        </ul>
        <p>
          Whether you're a designer, developer, or content creator, our Color Picker offers a user-friendly and efficient solution for selecting and applying colors in your applications. Experience
          the convenience and versatility of Color and enhance your projects with vibrant and visually appealing colors!
        </p>
      </div>
    </div>
  )
}

export default ColorPage
