import { useState, useRef, useCallback, useEffect } from 'react'
import QRCode from 'qrcode.react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import Button from '../components/common/Button'
import { Helmet } from 'react-helmet'
import Switch from 'react-switch'

const QRPage = () => {
  const [text, setText] = useState('')
  const [fileName, setFileName] = useState('qrcode')
  const [followName, setFollowName] = useState(false)
  const svgRef = useRef()

  const downloadBlob = (blob, filename) => {
    const objectUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = objectUrl
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
  }

  const downloadAsSVG = useCallback(() => {
    const content = svgRef.current.children[0].innerHTML
    const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="200" width="200" viewBox="0 0 29 29">
     ${content}
     </svg>`
    const blob = new Blob([contentWithSvg], { type: 'image/svg+xml' })
    downloadBlob(blob, fileName + '.svg')
  }, [fileName])

  const downloadAsPNG = useCallback(() => {
    const canvas = document.querySelector('.PngQrcode > canvas')
    const downloadLink = document.createElement('a')
    downloadLink.href = canvas.toDataURL()
    downloadLink.download = fileName + '.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }, [fileName])

  useEffect(() => {
    if (followName) {
      setFileName(text ?? 'qrcode')
    } else {
      setFileName('qrcode')
    }
  }, [text, followName])

  return (
    <div className="page">
      <Helmet>
        <title>QR Code Generator</title>
        <meta charSet="utf-8" />
        <meta name="description" content="This is the screen to generate the qr code. You can download it as png or svg." />
        <meta name="keywords" content="qr code, qr, qrcode, qr code generator, qrcode generator"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="QR Code Generator" />
        <meta property="og:description" content="This is the screen to generate the qr code." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        QR Code Generator
      </Typography>
      <div className="small-vertical-margin-div" />
      <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
        This is the screen to generate the qr code.
      </Typography>
      <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
        You can download it as png or svg.
      </Typography>
      <div className="small-vertical-margin-div" />
      <TextField
        fullWidth
        placeholder="Enter text"
        variant="outlined"
        inputProps={{
          style: {
            fontSize: 14,
          },
          overflow: 'auto',
        }}
        onChange={(e) => {
          setText(e.target.value)
        }}
      />
      <div className="medium-vertical-margin-div" />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <div className="SvgQrcode" ref={svgRef}>
            <QRCode renderAs={'svg'} value={text} size={200} />
          </div>
          <div className="PngQrcode" style={{ marginTop: '-205px' }}>
            <QRCode renderAs={'canvas'} value={text} size={200} />
          </div>
        </div>
        <div className="medium-horizontal-margin-div" />
        <div style={{ width: '250px' }}>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Image Name</span>
          </div>
          <TextField
            fullWidth
            placeholder="Enter image name"
            variant="outlined"
            value={fileName}
            inputProps={{
              style: {
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (e.target.value !== fileName) {
                setFileName(e.target.value)
              }
            }}
          />
          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Use same file name as value</span>
          </div>
          <Switch
            checked={followName}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(value) => {
              setFollowName(value)
            }}
          />
        </div>
        <div className="medium-horizontal-margin-div" />
        <div>
          <Button onClick={downloadAsPNG}>Download as PNG</Button>
          <div className="small-vertical-margin-div" />
          <Button onClick={downloadAsSVG}>Download as SVG</Button>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our QR Code Generator Page</h1>
        <p>
          QR Code Generator is a versatile tool for generating QR codes in your applications. Whether you're a business owner, developer, or content creator, our QR Code Generator offers a convenient
          solution for creating QR codes for various purposes.
        </p>
        <br />
        <h2>What can you do with our QR Code Generator?</h2>
        <ul>
          <li>Customizable QR Codes: Generate QR codes with customizable options such as size, color, and error correction level to match your design preferences.</li>
          <br />
          <li>Dynamic Content: Generate QR codes for different types of content including URLs, text, contact information, Wi-Fi credentials, and more.</li>
          <br />
          <li>Real-time Preview: Preview generated QR codes in real-time to see how they will appear before finalizing.</li>
          <br />
          <li>Accessibility: Ensure accessibility by providing options for high contrast and compatibility with screen readers.</li>
          <br />
          <li>Integration with React: Seamlessly integrate the qrcode.react library into your applications with minimal setup and configuration.</li>
          <br />
          <li>Responsive Design: Support for responsive design, ensuring optimal user experience across different devices and screen sizes.</li>
          <br />
        </ul>
        <p>
          Whether you're generating QR codes for marketing campaigns, event promotions, or personal use, our QR Code Generator offers a user-friendly and efficient solution for creating QR codes in
          your applications. Experience the convenience and versatility of QR codes with our QR Code Generator and enhance your projects with seamless QR code generation!
        </p>
      </div>
    </div>
  )
}

export default QRPage
