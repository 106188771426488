import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { useState, useCallback, useEffect } from 'react'
import Switch from 'react-switch'
import { Helmet } from 'react-helmet'
import { useDropzone } from 'react-dropzone'
import { readFile } from '../utils/Utils'
import { useFilePicker } from 'use-file-picker'
import Button from '../components/common/Button'

const ComparePage = () => {
  const [text1, setText1] = useState('')
  const [text2, setText2] = useState('')
  const [splitView, setSplitView] = useState(true)

  const onLeftDrop = useCallback(async (acceptedFiles) => {
    const fileContent = await readFile(acceptedFiles[0])
    setText1(fileContent)
  }, [])
  const onRightDrop = useCallback(async (acceptedFiles) => {
    const fileContent = await readFile(acceptedFiles[0])
    setText2(fileContent)
  }, [])
  const [leftHint, setLeftHint] = useState('Enter Text or drag file here.')
  const [rightHint, setRightHint] = useState('Enter Text or drag file here.')
  const leftDropZone = useDropzone({ noClick: true, onDrop: onLeftDrop })
  const rightDropZone = useDropzone({ noClick: true, onDrop: onRightDrop })

  const [openFileSelector1, { filesContent: filesContent1 }] = useFilePicker({
    multiple: false,
  })
  useEffect(() => {
    if (filesContent1.length > 0) {
      setText1(filesContent1[0].content)
    }
  }, [filesContent1])

  const [openFileSelector2, { filesContent: filesContent2 }] = useFilePicker({
    multiple: false,
  })
  useEffect(() => {
    if (filesContent2.length > 0) {
      setText2(filesContent2[0].content)
    }
  }, [filesContent2])

  useEffect(() => {
    if (leftDropZone.isDragActive) {
      setLeftHint('Drag file here.')
    } else {
      setLeftHint('Enter text or drag file here.')
    }
  }, [leftDropZone.isDragActive])

  useEffect(() => {
    if (rightDropZone.isDragActive) {
      setRightHint('Drag file here.')
    } else {
      setRightHint('Enter text or drag file here.')
    }
  }, [rightDropZone.isDragActive])

  return (
    <div className="page">
      <Helmet>
        <title>Compare Text</title>
        <meta charSet="utf-8" />
        <meta name="description" content="A screen that compares two text contents or files and shows the differences." />
        <meta name="keywords" content="compare, compare texts, compare files, compare contents"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Compare Text" />
        <meta property="og:description" content="This screen compares two strings and shows the difference." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        Compare Text
      </Typography>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            Results can be viewed on one page or split across two pages.
          </Typography>
        </div>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Button onClick={openFileSelector1}>Select Left File</Button>
          <div className="small-horizontal-margin-div" />
          <Button onClick={openFileSelector2}>Select Right File</Button>
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <div className="container">
        <div {...leftDropZone.getRootProps({ style: { flex: 1 } })}>
          <TextField
            id="text1"
            multiline={true}
            fullWidth
            placeholder={leftHint}
            value={text1}
            variant="outlined"
            rows={4}
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
            inputProps={{
              style: {
                height: '250px',
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (text1 !== e.target.value) {
                setText1(e.target.value)
              }
            }}
          />
        </div>
        <div className="small-horizontal-margin-div" />
        <div {...rightDropZone.getRootProps({ style: { flex: 1 } })}>
          <TextField
            id="text2"
            multiline={true}
            fullWidth
            placeholder={rightHint}
            value={text2}
            variant="outlined"
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
            inputProps={{
              style: {
                height: '250px',
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (text2 !== e.target.value) {
                setText2(e.target.value)
              }
            }}
          />
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
          Result
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="h1" component="h1" sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Split View
          </Typography>
          <div className="small-horizontal-margin-div" />
          <Switch
            checked={splitView}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(value) => {
              setSplitView(value)
            }}
          />
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <ReactDiffViewer
        styles={{
          diffContainer: {
            fontSize: '14px',
          },
        }}
        oldValue={text1}
        newValue={text2}
        compareMethod={DiffMethod.CHARS}
        splitView={splitView}
        showDiffOnly={false}
      />
      <div style={{ height: '50px' }} />
      <div>
        <h1>Welcome to our Diff Viewer Page</h1>
        <p>
          Diff Viewer is a powerful tool for visualizing and comparing differences between two pieces of text or code in a user-friendly manner. Whether you're a developer reviewing changes in code, a
          writer comparing versions of a document, or simply analyzing textual variances, our Diff Viewer makes the process seamless and efficient.
        </p>
        <br />
        <h2>What can you do with our Text Diff Viewer?</h2>
        <ul>
          <li>Interactive Diffing: Easily compare two text contents side by side with interactive highlighting to identify added, removed, or modified lines.</li>
          <br />
          <li>Syntax Highlighting: Enjoy syntax highlighting for various programming languages, making it easier to understand differences in code.</li>
          <br />
          <li>Inline and Split View: Choose between inline and split view modes to visualize differences based on your preference.</li>
          <br />
          <li>Customizable Themes: Tailor the appearance of the viewer to your liking with customizable themes and styles.</li>
          <br />
          <li>Extensive Compatibility: Diff Viewer is compatible with various applications and can be easily integrated into your projects.</li>
          <br />
          <li>
            User-Friendly Interface: With an intuitive and user-friendly interface, Diff Viewer simplifies the process of comparing text differences, making it accessible to users of all levels.
          </li>
          <br />
        </ul>
        <p>
          Whether you're a developer, writer, or content creator, Diff Viewer offers a versatile and efficient solution for comparing text differences. Experience the power of visualizing changes in
          text with Diff Viewer and enhance your productivity today!
        </p>
      </div>
    </div>
  )
}

export default ComparePage
