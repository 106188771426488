import React from 'react'
import { Link as EmailLink } from '@react-email/link'
import { NavLink } from 'react-router-dom'

const Email = () => {
  return <EmailLink href="mailto:hanbit2.kim@gmail.com">Email</EmailLink>
}

const ContactView = () => {
  return (
    <div className="contact-view" style={{ padding: '30px', backgroundColor: '#f2f2f2', marginTop: '100px' }}>
      <h2>
        <NavLink to="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </NavLink>
      </h2>
      <h2>
        <NavLink to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </NavLink>
      </h2>
      <h2>Contact Us</h2>
      <p>If you have any questions or inquiries, feel free to reach out to us:</p>
      <p>Any comments are welcome!</p>
      <div className="contact-details">
        <div className="contact-item">
          <span className="contact-icon" style={{ marginRight: '10px' }}>
            ✉️
          </span>
          <Email />
        </div>
      </div>
    </div>
  )
}

export default ContactView
