import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Navigation from './components/common/Navigation'
import RouteChangeTracker from './RouteChangeTracker'
import HomePage from './pages/HomePage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TermsAndConditionsPage from './pages/TermsAndConditionsPage'
import ComparePage from './pages/ComparePage'
import JSONPage from './pages/JSONPage'
import HTMLPage from './pages/HTMLPage'
import XMLPage from './pages/XMLPage'
import EncodingPage from './pages/EncodingPage'
import ImageToBase64Page from './pages/ImageToBase64Page'
import Base64ToImagePage from './pages/Base64ToImagePage'
import ColorPage from './pages/ColorPage'
import QRPage from './pages/QRPage'

function App() {
  RouteChangeTracker()

  const location = useLocation()

  const paddingTop = location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions' ? 0 : '50px'

  return (
    <div>
      {location.pathname !== '/privacy-policy' && location.pathname !== '/terms-and-conditions' && <Navigation />}
      <div style={{ paddingTop: paddingTop }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/json" element={<JSONPage />} />
          <Route path="/compare" element={<ComparePage />} />
          <Route path="/html" element={<HTMLPage />} />
          <Route path="/xml" element={<XMLPage />} />
          <Route path="/encoding" element={<EncodingPage />} />
          <Route path="/image-to-base64" element={<ImageToBase64Page />} />
          <Route path="/base64-to-image" element={<Base64ToImagePage />} />
          <Route path="/color" element={<ColorPage />} />
          <Route path="/qr" element={<QRPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
