import React from 'react'
import { NavLink } from 'react-router-dom'
import '../../../src/lib/styles/css/Navigation.css'

const Navigation = () => {
  return (
    <nav className="scrollable-nav">
      <div>
        <NavLink to="/">Home</NavLink>
      </div>
      <div>
        <NavLink to="/json">JSON Formatter</NavLink>
      </div>
      <div>
        <NavLink to="/compare">Compare Text</NavLink>
      </div>
      <div>
        <NavLink to="/html">HTML Formatter</NavLink>
      </div>
      <div>
        <NavLink to="/xml">XML Formatter</NavLink>
      </div>
      <div>
        <NavLink to="/encoding">Encoding & Decoding</NavLink>
      </div>
      <div>
        <NavLink to="/image-to-base64">Image to Base64</NavLink>
      </div>
      <div>
        <NavLink to="/base64-to-image">Base64 to Image</NavLink>
      </div>
      <div>
        <NavLink to="/color">Color Picker</NavLink>
      </div>
      <div>
        <NavLink to="/qr">QR code</NavLink>
      </div>
    </nav>
  )
}

export default Navigation
