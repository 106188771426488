import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import { Helmet } from 'react-helmet'
import { useDropzone } from 'react-dropzone'
import { useFilePicker } from 'use-file-picker'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../components/common/Button'

const ImageToBase64Page = () => {
  const [text, setText] = useState('')

  const textFieldRef = useRef(null)

  const onDrop = useCallback(async (acceptedFiles) => {
    convertToBase64(acceptedFiles[0])
  }, [])
  const [hint, setHint] = useState('Select image or drag image here.')
  const dropZone = useDropzone({ noClick: true, onDrop })
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: 'image/*',
    multiple: false,
  })

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(setText(reader.result.split(',')[1]))
      reader.onerror = (error) => reject(setText(error))
    })
  }

  const handleCopyClick = () => {
    const textFieldValue = textFieldRef.current.value
    navigator.clipboard.writeText(textFieldValue).then(() => {
      toast.success('Copied to clipboard', { autoClose: 1000 })
    })
  }

  useEffect(() => {
    if (filesContent.length > 0) {
      const file = filesContent[0]
      convertToBase64(file)
    }
  }, [filesContent])

  useEffect(() => {
    if (dropZone.isDragActive) {
      setHint('Drag image here.')
    } else {
      setHint('Select image or drag image here.')
    }
  }, [dropZone.isDragActive])

  return (
    <div className="page">
      <Helmet>
        <title>Image to Base64 Converter</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Image to Base64 Encoder helps to convert image to base64 String. Copy base64 string to clipboard." />
        <meta name="keywords" content="image to base64, base64, image, image encoding, image encoder"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Online Image to Base64 Encoder" />
        <meta property="og:description" content="Image to Base64 Encoder helps to convert image to base64 String. Copy base64 string to clipboard." />
      </Helmet>
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
          Image to Base64 Converter
        </Typography>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Button onClick={openFileSelector}>Select Image</Button>
          <div className="small-horizontal-margin-div" />
          <Button onClick={handleCopyClick}>Copy to Clipboard</Button>
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <div {...dropZone.getRootProps({ style: { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' } })}>
          <img src={`data:image/jpeg;base64,${text}`} style={{ maxWidth: '100%', maxHeight: '100%', position: 'absolute' }} />

          <div style={{ flex: 1, position: 'relative', zIndex: 1 }}>
            <TextField
              style={{ flex: 1 }}
              fullWidth
              placeholder={text ? '' : hint}
              variant="outlined"
              disabled
              multiline
              rows={4}
              inputProps={{
                style: {
                  height: '65vh',
                  fontSize: 14,
                },
              }}
            />
          </div>
        </div>

        <div className="small-horizontal-margin-div" />
        <TextField
          inputRef={textFieldRef}
          style={{ flex: 1 }}
          fullWidth
          variant="outlined"
          value={text}
          multiline
          rows={4}
          sx={{
            flex: 1,
            overflow: 'auto',
          }}
          inputProps={{
            style: {
              height: '65vh',
              fontSize: 14,
            },
            overflow: 'auto',
          }}
        />
      </div>
      <div style={{ marginTop: '50px' }}>
        <header>
          <h1>Online Base64 to Image Converter</h1>
        </header>

        <main>
          <section>
            <h2>What can you do with our ImageToBase64 Converter?</h2>
            <ul>
              <li>Convert image files to Base64 format.</li>
              <li>Easily copy the converted Base64 string to clipboard.</li>
              <li>User-friendly interface for seamless conversion experience.</li>
            </ul>
          </section>

          <section>
            <h2>How to Use</h2>
            <ol>
              <li>Select or drag and drop an image file onto the converter.</li>
              <li>Get the Base64 string instantly.</li>
              <li>Copy the Base64 string to clipboard with a single click.</li>
            </ol>
          </section>

          <section>
            <h2>Benefits of Base64 Conversion</h2>
            <p>Base64 encoding offers several advantages including:</p>
            <ul>
              <li>Reduced server requests by embedding images directly into HTML/CSS.</li>
              <li>Better performance and faster loading times for web pages.</li>
            </ul>
          </section>
        </main>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ImageToBase64Page
