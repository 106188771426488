import styled from 'styled-components'
import palette from '../../lib/styles/palette'

const StyledButton = styled.button`
  border: none;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1rem;
  color: white;
  outline: none;
  cursor: pointer;

  background-color: darkcyan;
  &:hover {
    background: ${palette.gray[6]};
  }
`

const Button = (props) => <StyledButton {...props} />

export default Button
