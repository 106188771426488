import React from 'react'
import { useState, useCallback, useRef, useEffect } from 'react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import prettier from 'prettier/standalone'
import parserHtml from 'prettier/parser-html'
import Button from '../components/common/Button'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
import { useDropzone } from 'react-dropzone'
import { readFile } from '../utils/Utils'
import { useFilePicker } from 'use-file-picker'

const HTMLPage = () => {
  const [text, setText] = useState('')
  const textFieldRef = useRef(null)
  const onDrop = useCallback(async (acceptedFiles) => {
    const fileContent = await readFile(acceptedFiles[0])
    setText(fileContent)
  }, [])
  const [hint, setHint] = useState('Enter HTML or drag file here.')
  const dropZone = useDropzone({ noClick: true, onDrop })
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.html',
    multiple: false,
  })
  useEffect(() => {
    if (filesContent.length > 0) {
      setText(filesContent[0].content)
    }
  }, [filesContent])

  useEffect(() => {
    if (dropZone.isDragActive) {
      setHint('Drag file here.')
    } else {
      setHint('Enter HTML or drag file here.')
    }
  }, [dropZone.isDragActive])

  const formatHtml = useCallback(() => {
    try {
      const formattedHtml = prettier.format(text, {
        parser: 'html',
        plugins: [parserHtml],
      })
      return formattedHtml
    } catch (error) {
      return text
    }
  }, [text])

  const handleCopyClick = () => {
    const textFieldValue = textFieldRef.current.value
    navigator.clipboard.writeText(textFieldValue).then(() => {
      toast.success('Copied to clipboard', { autoClose: 1000 })
    })
  }

  const showBrowser = () => {
    const modalHTML = `<div style="background-color: #fff; padding: 20px;">${text}</div>`

    const modalWidth = 800
    const modalHeight = 600

    const left = window.screen.width / 2 - modalWidth / 2
    const top = window.screen.height / 2 - modalHeight / 2

    const newWindow = window.open('', '_blank', `width=${modalWidth},height=${modalHeight},left=${left},top=${top}`)

    newWindow.document.write(modalHTML)
    newWindow.document.close()
  }

  return (
    <div className="page">
      <Helmet>
        <title>HTML Formatter</title>
        <meta charSet="utf-8" />
        <meta name="description" content="This is the screen for formatting HTML. You can align HTML formatted." />
        <meta name="keywords" content="html, html viewer, html formatter"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="HTML Formatter" />
        <meta property="og:description" content="This is the screen for formatting HTML." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        HTML Formatter
      </Typography>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            You can align HTML formatted.
          </Typography>
        </div>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Button onClick={openFileSelector}>Select File</Button>
          <div className="small-horizontal-margin-div" />
          <Button onClick={showBrowser}>Show Browser</Button>
          <div className="small-horizontal-margin-div" />
          <Button onClick={handleCopyClick}>Copy to Clipboard</Button>
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <div {...dropZone.getRootProps({ style: { flex: 1 } })}>
          <TextField
            inputRef={textFieldRef}
            style={{ flex: 1 }}
            fullWidth
            placeholder={hint}
            value={text}
            variant="outlined"
            multiline
            rows={4}
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
            inputProps={{
              style: {
                height: '65vh',
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (text !== e.target.value) {
                setText(e.target.value)
              }
            }}
          />
        </div>
        <div className="small-horizontal-margin-div" />
        <div className="border-container" style={{ flex: 1, padding: '0px 20px', overflow: 'auto', borderRadius: '4px' }}>
          <pre>{text && formatHtml()}</pre>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our HTML Formatter Page</h1>
        <p>
          Our HTML Formatter, powered by prettier/parser-html, is a versatile tool for automatically formatting and beautifying HTML code. Whether you're a developer working on a large-scale web
          project or a beginner learning HTML, our formatter ensures clean and organized markup, making your code more readable and maintainable.
        </p>
        <br />
        <h2>What can you do with our HTML Formatter?</h2>
        <ul>
          <li>Automatic Formatting: Automatically format HTML code according to predefined rules, ensuring consistent and standardized markup.</li>
          <br />
          <li>Readable Output: Beautify HTML code for improved readability and easier code review.</li>
          <br />
          <li>Preserve Code Structure: Retain the structure of your HTML code while applying formatting, ensuring that your code remains logically organized.</li>
          <br />
          <li>Customizable Options: Customize formatting options to suit your preferences and project requirements.</li>
          <br />
          <li>Integration with Prettier: Leveraging the power of prettier/parser-html, our HTML Formatter provides seamless integration with the popular Prettier code formatting tool.</li>
          <br />
          <li>Extensive Compatibility: Our HTML Formatter is compatible with various HTML documents and can be easily integrated into your web development workflow.</li>
          <br />
        </ul>
        <p>
          Whether you're a seasoned developer or just starting out, our HTML Formatter offers a convenient and efficient solution for maintaining clean and organized HTML code. Experience the benefits
          of automated code formatting with our HTML Formatter and elevate your web development projects!
        </p>
      </div>
      <ToastContainer />
    </div>
  )
}

export default HTMLPage
