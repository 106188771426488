import React from 'react'
import { useState, useCallback, useRef, useEffect } from 'react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import XMLParser from 'react-xml-parser'
import Button from '../components/common/Button'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
import { useDropzone } from 'react-dropzone'
import { readFile } from '../utils/Utils'
import { useFilePicker } from 'use-file-picker'
import XMLViewer from 'react-xml-viewer'

const XMLPage = () => {
  const [text, setText] = useState('')
  const textFieldRef = useRef(null)
  const onDrop = useCallback(async (acceptedFiles) => {
    const fileContent = await readFile(acceptedFiles[0])
    setText(fileContent)
  }, [])
  const [hint, setHint] = useState('Enter XML or drag file here.')
  const dropZone = useDropzone({ noClick: true, onDrop })
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.xml',
    multiple: false,
  })
  useEffect(() => {
    if (filesContent.length > 0) {
      setText(filesContent[0].content)
    }
  }, [filesContent])

  useEffect(() => {
    if (dropZone.isDragActive) {
      setHint('Drag file here.')
    } else {
      setHint('Enter XML or drag file here.')
    }
  }, [dropZone.isDragActive])

  const isValidXml = useCallback(() => {
    try {
      new XMLParser().parseFromString(text)
      return true
    } catch (error) {
      return false
    }
  }, [text])

  const handleCopyClick = () => {
    const textFieldValue = textFieldRef.current.value
    navigator.clipboard.writeText(textFieldValue).then(() => {
      toast.success('Copied to clipboard', { autoClose: 1000 })
    })
  }

  return (
    <div className="page">
      <Helmet>
        <title>XML Formatter</title>
        <meta charSet="utf-8" />
        <meta name="description" content="This is the screen for formatting XML. You can align XML formatted." />
        <meta name="keywords" content="xml, xml viewer, xml formatter"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="XML Formatter" />
        <meta property="og:description" content="This is the screen for formatting XML." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        XML Formatter
      </Typography>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            This is the screen for formatting XML.
          </Typography>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            You can align XML formatted.
          </Typography>
        </div>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Button onClick={openFileSelector}>Select File</Button>
          <div className="small-horizontal-margin-div" />
          <Button onClick={handleCopyClick}>Copy to Clipboard</Button>
        </div>
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <div {...dropZone.getRootProps({ style: { flex: 1 } })}>
          <TextField
            inputRef={textFieldRef}
            style={{ flex: 1 }}
            fullWidth
            placeholder={hint}
            value={text}
            variant="outlined"
            multiline
            rows={4}
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
            inputProps={{
              style: {
                height: '65vh',
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (text !== e.target.value) {
                setText(e.target.value)
              }
            }}
          />
        </div>
        <div className="small-horizontal-margin-div" />
        <div className="border-container" style={{ flex: 1, padding: '0px 20px', overflow: 'auto', borderRadius: '4px' }}>
          {text && isValidXml() && <XMLViewer xml={text} collapsible={true} />}
          {text && !isValidXml() && 'Invalid XML format'}
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our XML Viewer Page</h1>
        <p>
          XML Viewer is a versatile and powerful tool for visualizing and navigating XML data structures in a user-friendly manner. Whether you're a developer working with XML-based APIs, a data
          analyst exploring XML datasets, or a front-end engineer integrating XML content into your applications, XML Viewer provides a comprehensive solution for working with XML data.
        </p>
        <br />
        <h2>What can you do with our XML Viewer?</h2>
        <ul>
          <li>Interactive Visualization: Easily navigate through XML data structures with collapsible nodes and expandable elements.</li>
          <br />
          <li>Syntax Highlighting: Enjoy syntax highlighting for improved readability and understanding of XML syntax.</li>
          <br />
          <li>Dynamic Data Display: View large XML data sets with ease, as XML Viewer efficiently handles rendering and updates.</li>
          <br />
          <li>Data Transformation: Interact with XML data in real-time by collapsing, expanding, and filtering nodes based on your preferences.</li>
          <br />
          <li>Customizable Themes: Tailor the appearance of the XML viewer to match your application's design with customizable themes and styles.</li>
          <br />
          <li>Error Highlighting: Identify and troubleshoot XML parsing errors with clear error messages and visual indicators.</li>
          <br />
          <li>Extensive Compatibility: XML Viewer is compatible with various applications and can be seamlessly integrated into your projects.</li>
          <br />
        </ul>
        <p>
          Whether you're visualizing XML responses from APIs, exploring XML datasets, or integrating XML content into your applications, XML Viewer offers a user-friendly and efficient solution for
          working with XML data. Experience the power of visualizing XML data structures with XML Viewer and enhance your development workflow today!
        </p>
      </div>
      <ToastContainer />
    </div>
  )
}

export default XMLPage
