import React from 'react'
import { Helmet } from 'react-helmet'
import ContactView from '../components/common/ContactView'

const contentHtml = () => {
  return (
    <div className="container">
      <div>
        <header>
          <h1>Welcome to Developer tools!</h1>
          <p>
            Developer Tools is your ultimate destination for a comprehensive suite of developer tools designed to streamline your development process and enhance your productivity. Our platform offers
            a wide range of features to meet your coding needs, whether you're working with JSON, HTML, XML, or need to compare text, encode & decode, select colors, and even generate QR codes!
            Additionally, with our Image to Base64 and Base64 to Image conversion tools, you can easily manage image data for seamless integration into your projects.
          </p>

          <p>
            Provide developers with powerful and intuitive tools that simplify complex tasks and accelerate your workflow. With Developer tools, you can spend less time on repetitive tasks and focus
            more on building amazing software.
          </p>
        </header>
        <div style={{ marginTop: '80px' }}></div>
        <main>
          <section className="feature">
            <h2>Key Features</h2>
            <ul>
              <li>
                <strong>JSON Formatter:</strong> Easily format and beautify your JSON data for better readability and organization.
              </li>
              <br />
              <li>
                <strong>HTML Formatter:</strong> Clean up and structure your HTML code effortlessly, ensuring consistency and clarity.
              </li>
              <br />
              <li>
                <strong>XML Formatter:</strong> Quickly format XML documents with precision, making them easier to read and manage.
              </li>
              <br />
              <li>
                <strong>Compare Text:</strong> Seamlessly compare text strings or files to identify differences and track changes efficiently.
              </li>
              <br />
              <li>
                <strong>Encoding & Decoding:</strong> Encode or decode text in various formats (Base64, URL, etc.) for secure communication and data transmission.
              </li>
              <br />
              <li>
                <strong>Image to Base64 Encoder:</strong> Convert images to Base64 format for easy integration into web applications and data transmission.
              </li>
              <br />
              <li>
                <strong>Base64 to Image Decoder:</strong> Decode Base64 strings and convert them back to image format for various applications such as data storage and display.
              </li>
              <br />
              <li>
                <strong>Color Picker:</strong> Effortlessly select, copy, and manage colors in HEX, RGB, or HSL formats to enhance your design process.
              </li>
              <br />
              <li>
                <strong>QR Code Generator:</strong> Generate QR codes for any type of information (URLs, text, contact information, etc.) and download them as images for easy sharing and distribution.
              </li>
            </ul>
          </section>
        </main>
        <div style={{ marginTop: '80px' }}></div>
        <footer>
          <p>
            Developer tools is committed to empowering developers like you by providing a wide range of powerful and intuitive tools to simplify your workflow and enhance your coding experience. Our
            goal is to continually improve and expand our platform to meet the evolving needs of the developer community.
          </p>
          <p>
            Join us on this journey of innovation and creativity as we strive to create a vibrant and supportive ecosystem for developers worldwide. With Developer tools, you have the tools and
            resources you need to bring your ideas to life and make a meaningful impact in the world of technology.
          </p>
          <p>Take the next step in your development journey and unlock your full potential with Developer tools today!</p>
        </footer>
        <ContactView />
      </div>
    </div>
  )
}

const HomePage = () => {
  return (
    <div className="page">
      <Helmet>
        <title>Developer tools</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Welcome to Developer tools, your go-to platform for a comprehensive suite of tools designed exclusively for developers. Whether you're diving into JSON, HTML, XML, or need to compare text effortlessly, we've got you covered."
        />
        <meta name="keywords" content="json, html, xml, qr, qrcode, encoding, decoding, text compare, compare, color, color picker" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Developer tools" />
        <meta property="og:description" content="This is the main screen for developer tools." />
      </Helmet>
      {contentHtml()}
    </div>
  )
}

export default HomePage
