import React, { useState, useEffect, useRef } from 'react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import { Helmet } from 'react-helmet'
import Select from 'react-select'
import utf8 from 'utf8'

const EncodingPage = () => {
  const [originalText, setOriginalText] = useState('')
  const [encodedText, setEncodedText] = useState('')
  const originalTextFieldRef = useRef(null)
  const encodedTextFieldRef = useRef(null)

  const options = [
    { value: 'base64', label: 'Base64' },
    { value: 'utf-8', label: 'UTF-8' },
    { value: 'url', label: 'URL' },
  ]

  const [selectOption, setSelectOption] = useState(options[0])

  useEffect(() => {
    setOriginalText('')
  }, [selectOption])

  useEffect(() => {
    originalTextFieldRef.current.value = originalText
    if (originalText === '') {
      setEncodedText('')
    } else {
      if (selectOption.value === 'base64') {
        const encoded = btoa(originalText)
        if (encodedText !== encoded) {
          setEncodedText(encoded)
        }
      } else if (selectOption.value === 'utf-8') {
        const encoded = utf8.encode(originalText)
        if (encodedText !== encoded) {
          setEncodedText(encoded)
        }
      } else {
        const encoded = encodeURIComponent(originalText)
        if (encodedText !== encoded) {
          setEncodedText(encoded)
        }
      }
    }
  }, [originalText])

  useEffect(() => {
    encodedTextFieldRef.current.value = encodedText
    try {
      if (selectOption.value === 'base64') {
        const original = atob(encodedText)
        if (originalText !== original) {
          setOriginalText(original)
        }
      } else if (selectOption.value === 'utf-8') {
        const original = utf8.decode(encodedText)
        if (originalText !== original) {
          setOriginalText(original)
        }
      } else {
        const original = decodeURIComponent(encodedText)
        if (originalText !== original) {
          setOriginalText(original)
        }
      }
    } catch (e) {
      setOriginalText('')
    }
  }, [encodedText])

  return (
    <div className="page">
      <Helmet>
        <title>Encoding & Decoding</title>
        <meta charSet="utf-8" />
        <meta name="description" content="This is the screen for encoding & decoding as Base64, UTF-8, URL. Enter the encoded text on the left, or the original text on the right." />
        <meta name="keywords" content="encoding, decoding, base64, text encoding, text decoding, url encoding, url decoding"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Encoding & Decoding" />
        <meta property="og:description" content="This is the screen for encoding & decoding as Base64, UTF-8, URL." />
      </Helmet>
      <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        Encoding & Decoding
      </Typography>
      <div className="small-vertical-margin-div" />
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            This is the screen for encoding & decoding as Base64, UTF-8, URL.
          </Typography>
          <Typography variant="h1" component="h1" sx={{ fontSize: 16 }}>
            Enter the encoded text on the left, or the original text on the right.
          </Typography>
        </div>
        <Select options={options} onChange={setSelectOption} defaultValue={options[0]} />
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <TextField
          id="text1"
          inputRef={encodedTextFieldRef}
          style={{ flex: 1 }}
          fullWidth
          placeholder="Enter encoded text"
          variant="outlined"
          multiline
          rows={4}
          sx={{
            flex: 1,
            overflow: 'auto',
          }}
          inputProps={{
            style: {
              height: '65vh',
              fontSize: 14,
            },
            overflow: 'auto',
          }}
          onChange={(e) => {
            setEncodedText(e.target.value)
          }}
        />
        <div className="small-horizontal-margin-div" />
        <TextField
          id="text2"
          inputRef={originalTextFieldRef}
          style={{ flex: 1 }}
          fullWidth
          placeholder="Enter original text"
          variant="outlined"
          multiline
          rows={4}
          sx={{
            flex: 1,
            overflow: 'auto',
          }}
          inputProps={{
            style: {
              height: '65vh',
              fontSize: 14,
            },
            overflow: 'auto',
          }}
          onChange={(e) => {
            setOriginalText(e.target.value)
          }}
        />
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our Encoding and Decoding Tool</h1>
        <p>
          Our Encoding and Decoding Tool is a versatile web application that provides encoding and decoding functionalities for base64, UTF-8, and URL formats. Whether you're a developer working with
          data transmission, a content creator managing text content, or simply need to manipulate data in different formats, our tool offers a convenient solution for your encoding and decoding
          needs.
        </p>
        <br />
        <h2>What can you do with our Encoding and Decoding Tools?</h2>
        <ul>
          <li>Base64 Encoding and Decoding: Encode and decode data in base64 format, widely used for encoding binary data into ASCII characters.</li>
          <br />
          <li>UTF-8 Encoding and Decoding: Encode and decode text data in UTF-8 format, the most commonly used encoding for text files on the internet.</li>
          <br />
          <li>URL Encoding and Decoding: Encode and decode data in URL format, useful for encoding special characters in URLs to ensure proper transmission over the internet.</li>
          <br />
          <li>Interactive Interface: User-friendly interface for easy input of data and instant conversion between different encoding formats.</li>
          <br />
          <li>Secure and Efficient: Our tool ensures secure encoding and decoding processes while providing efficient conversion for your data manipulation needs.</li>
          <br />
          <li>Web Compatibility: Compatible with various web browsers and platforms, allowing seamless access and usage of our encoding and decoding functionalities.</li>
          <br />
        </ul>
        <p>
          Whether you're encoding data for secure transmission, decoding text content for analysis, or manipulating URLs for web applications, our Encoding and Decoding Tool offers a comprehensive
          solution for your data manipulation needs. Experience the convenience of encoding and decoding data in multiple formats with our versatile web application!
        </p>
      </div>
    </div>
  )
}

export default EncodingPage
