import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { Typography } from '@mui/material'
import { TextField } from '../../node_modules/@mui/material/index'
import ReactJson from 'react-json-view'
import { Helmet } from 'react-helmet'
import { useDropzone } from 'react-dropzone'
import { readFile } from '../utils/Utils'
import { useFilePicker } from 'use-file-picker'
import Button from '../components/common/Button'

const JSONPage = () => {
  const [text, setText] = useState('')

  const onDrop = useCallback(async (acceptedFiles) => {
    const fileContent = await readFile(acceptedFiles[0])
    setText(fileContent)
  }, [])
  const [hint, setHint] = useState('Enter JSON or drag file here.')
  const dropZone = useDropzone({ noClick: true, onDrop })
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.json',
    multiple: false,
  })
  useEffect(() => {
    if (filesContent.length > 0) {
      setText(filesContent[0].content)
    }
  }, [filesContent])

  useEffect(() => {
    if (dropZone.isDragActive) {
      setHint('Drag file here.')
    } else {
      setHint('Enter JSON or drag file here.')
    }
  }, [dropZone.isDragActive])

  const isValidJson = useCallback(() => {
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }, [text])

  const errorText = useCallback(() => {
    try {
      JSON.parse(text)
      return ''
    } catch (error) {
      return error.message
    }
  }, [text])

  return (
    <div className="page">
      <Helmet>
        <title>JSON Formatter</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Online JSON Viewer and Formatter. It will validate, beautify your input text." />
        <meta name="keywords" content="json, json viewer, json formatter"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Online JSON Viewer and Formatter" />
        <meta property="og:description" content="Online JSON Viewer and Formatter. It will validate, beautify your input text." />
      </Helmet>
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
          JSON Formatter
        </Typography>
        <Button onClick={openFileSelector}>Select File</Button>
      </div>
      <div className="small-vertical-margin-div" />
      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <div {...dropZone.getRootProps({ style: { flex: 1 } })}>
          <TextField
            style={{ flex: 1 }}
            fullWidth
            placeholder={hint}
            value={text}
            variant="outlined"
            multiline
            rows={4}
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
            inputProps={{
              style: {
                height: '65vh',
                fontSize: 14,
              },
              overflow: 'auto',
            }}
            onChange={(e) => {
              if (text !== e.target.value) {
                setText(e.target.value)
              }
            }}
          />
        </div>
        <div className="small-horizontal-margin-div" />
        <div className="border-container" style={{ flex: 1, padding: '20px', overflow: 'auto', borderRadius: '4px' }}>
          {text && isValidJson() && <ReactJson src={JSON.parse(text)} theme="summerfruit:inverted" displayDataTypes={false} />}
          {text && !isValidJson() && <div>{errorText()}</div>}
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <h1>Welcome to our online JSON Viewer!</h1>
        <br />
        <p>JSON is a data format that is gaining popularity and getting used extensively in many AJAX-powered websites because of its human-readable nature.</p>
        <p>
          Many websites offer APIs, which will return data in JSON format. Often the JSON provided has white space removed to reduce the payload size or data. Our JSON API formatter gives you a quick
          and easy way to format the JSON so you can read it. Our JSON Viewer displays image previews if the data is an image URL and simplifies JSON data.
        </p>
        <br />
        <h2>What can you do with our JSON Viewer?</h2>
        <ul>
          <li>Beautify / Format your JSON.</li>
          <br />
          <li>Parse and Display / Explore your JSON String into JSON tree view.</li>
          <br />
          <li>Minify / Compress your JSON.</li>
          <br />
          <li>Validate your JSON and assist in error resolution.</li>
          <br />
          <li>JSON Converter Online: Transform your JSON structure into XML format or export it to CSV format.</li>
          <br />
          <li>Hover over image URLs, and our JSON visualizer will display the image.</li>
          <br />
          <li>After creating JSON Data, download it as a file or save it as a link to share.</li>
          <br />
          <li>Our JSON Viewer is compatible with Windows, MAC, Chrome, and Firefox.</li>
          <br />
          <li>JSON Pretty Print / Pretty JSON Tool to enhance the readability of JSON data.</li>
          <br />
          <li>JSON File Viewer: View JSON files online by uploading them.</li>
        </ul>
        <br />
        <h2>How to use the JSON Viewer?</h2>
        <p>Step 1: Click on the File Button at the top center of this page to open the file selection dialog of your operating system.</p>
        <p>Step 2: Select the JSON file, and this tool will display the JSON in the parent node tree.</p>
      </div>
    </div>
  )
}

export default JSONPage
