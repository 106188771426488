import React, { useEffect, useRef, useState } from 'react'
import { Typography, TextField } from '@mui/material'
import { Helmet } from 'react-helmet'
import Button from '../components/common/Button'

const Base64ToImagePage = () => {
  const [base64String, setBase64String] = useState('')
  const imageRef = useRef(null)

  const handleDownloadClick = () => {
    const link = document.createElement('a')
    link.href = imageRef.current.src
    link.download = 'image.png'
    link.click()
  }

  useEffect(() => {
    if (base64String) {
      const image = new Image()
      image.src = `data:image/png;base64,${base64String}`
      image.onload = () => {
        // 이미지가 로드된 후 이미지 레퍼런스 업데이트
        imageRef.current.src = image.src
      }
    } else {
      imageRef.current.src = null
    }
  }, [base64String])

  return (
    <div className="page">
      <Helmet>
        <title>Base64 to Image Converter</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Online Base64 to Image Converter - Easily convert Base64 string to image. Download the converted image instantly." />
        <meta name="keywords" content="base64, image, converter, online, base64 to image" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Online Base64 to Image Converter" />
        <meta property="og:description" content="Base64 to Image Converter helps to convert image to base64 String. Copy base64 string to clipboard." />
      </Helmet>
      <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h1" component="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
          Base64 to Image Converter
        </Typography>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Button variant="contained" onClick={handleDownloadClick} disabled={!base64String}>
            Download Image
          </Button>
        </div>
      </div>

      <div className="small-vertical-margin-div" />

      <div style={{ flex: 1, display: 'flex', direction: 'row' }}>
        <div style={{ flex: 1, position: 'relative', zIndex: 1 }}>
          <TextField
            style={{ flex: 1 }}
            placeholder="Enter image base64 text."
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            inputProps={{
              style: {
                height: '65vh',
                fontSize: 14,
              },
            }}
            onChange={(e) => setBase64String(e.target.value)}
          />
        </div>
        <div className="small-horizontal-margin-div" />

        <div className="border-container" style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: '0px 20px', borderRadius: '4px' }}>
          <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%', position: 'absolute' }} />
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <header>
          <h1>Online Base64 to Image Converter</h1>
          <p>Convert Base64 string to image format instantly and download the converted image with a single click!</p>
        </header>

        <main>
          <section>
            <h2>What can you do with our Base64ToImage Converter?</h2>
            <ul>
              <li>Convert Base64 string to image format.</li>
              <li>Download the converted image instantly.</li>
              <li>User-friendly interface for seamless conversion experience.</li>
            </ul>
          </section>

          <section>
            <h2>How to Use</h2>
            <ol>
              <li>Enter or paste the Base64 string into the input field below.</li>
              <li>Click the "Convert" button to generate the image.</li>
              <li>Download the converted image using the "Download Image" button.</li>
            </ol>
          </section>

          <section>
            <h2>Benefits of Base64 to Image Conversion</h2>
            <p>Converting Base64 to image format offers several advantages including:</p>
            <ul>
              <li>Instant conversion without the need for additional software.</li>
              <li>Efficient handling of Base64-encoded images for web development.</li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  )
}

export default Base64ToImagePage
